<script setup>
  import app from '@/main'
  import { useRouter } from 'vue-router'

  const props = defineProps({
    userId: {
      type: String,
      required: true
    }
  })

  const router = useRouter()

  async function moreContentClicked () {
    await app.wsp.sendRequest({
      data: {
        userId: props.userId
      },
      method: 'moreContent.clicked'
    })

    router.push('/chat/' + props.userId)
  }
</script>

<template>
  <button
    type="button"
    class="whitespace-nowrap flex items-center justify-center gap-1.5 px-3.5 py-2 border border-primary rounded-md text-sm font-bold text-primary hover:text-white hover:bg-primary"
    @click="moreContentClicked">
    <span>{{ $t('common.more_content') }}</span>

    <InlineSvg
      class="w-4 h-4"
      :src="require('@/assets/fontawesome/regular/image-polaroid.svg')"/>
  </button>
</template>